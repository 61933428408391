<template :@wheel="closeSelect">
  <div class="home" v-if="!_isMobile()">
    <tou class="tou" v-show="show"></tou>
    <div class="one">
      <swiper></swiper>
    </div>
    <div class="two" @mouseenter="numshow = true" @mouseleave="numshow = false">
      <img class="backimg" src="../assets/home/aboutback.png" alt="" style="" />
      <div class="content">
        <div class="left">
          <div class="videoImg">
            <!-- <img :src="aboutLeft_img" alt="" /> -->
            <video :src="aboutLeft_img" controls autoplay></video>
            <div class="videoButton" @click="videoClick">
              <img src="../assets/home/videoButton.png" alt="" />
            </div>
          </div>
          <div class="videoPlay" v-show="videoPlay">
            <div class="videoPut">
              <div class="videoIcon" @click="iconFalse">
                <i class="iconfont">&#xe614;</i>
              </div>
              <video :src="home_video" muted autoplay loop controls></video>
            </div>
          </div>
          <div class="num">
            <div
              class="num-item"
              :class="'numitem' + index"
              v-for="(item, index) in numlist"
              :key="index"
            >
              <div class="num-top">
                <countTo
                  v-if="numshow"
                  :startVal="0"
                  :endVal="item.num"
                  :duration="item.duration"
                  @animationend="numshow = false"
                  separator=""
                ></countTo
                ><span class="dan">{{ item.dan }}</span>
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="but" @click="layoutGoLink">{{ lookMore }} &nbsp;→</div>
        </div>
        <div class="right">
          <div class="right-top">
            <p class="p1" v-if="getLangText === 'CN'">{{ this.$t("aboutHeng") }}</p>
            <p class="p2" v-if="getLangText === 'CN'">ABOUT HENGLIAN</p>
            <p class="p1" v-if="getLangText === 'EN'" style="margin-top: 0.22rem;">{{ this.$t("aboutHeng") }}</p>
            <p class="p2"  v-if="getLangText === 'EN'" style="height: 0.13rem"></p>
            <p class="p3">{{ home_about }}</p>
          </div>
          <img
            :src="aboutRight_img"
            alt=""
            style="width: 8.58rem; height: 4.61rem; margin-top: 0.51rem"
          />
        </div>
      </div>
    </div>
    <div class="three">
      <div
        class="background-animation"
        :style="'background-image: url(' + threeback + ')'"
      >
        <div class="content">
          <div class="left">
            <p class="p1">{{ layoutTitleCn }}</p>
            <p class="p2"  v-if="getLangText === 'CN'">{{ layoutTitleEn }}</p>
            <p class="p3" v-html="layoutText"></p>
            <p class="p4">
              0{{ clickindex
              }}<span style="font-size: 0.4rem">&nbsp;/&nbsp;</span
              ><span>04</span>
            </p>
          </div>
          <div class="mid">
            <div class="quan1">
              <div class="quan2"></div>
              <img
                class="xian"
                :style="{
                  transform: transform,
                  left: left + 'rem',
                  top: topi + 'rem',
                }"
                src="../assets/home/xian.png"
                alt=""
                style=""
              />
              <!-- <div v-for="(item, index) in industrylist" :key="index">
                <div
                  :class="'item' + index"
                  class="item"
                  @click="clicklayout(item, index)"
                  v-show="index != 0"
                >
                  <div
                    class="item-img"
                    :style="'background-image: url(' + item.image + ')'"
                    src=""
                    alt=""
                  ></div>
                  <div class="item-wenben">
                    <p class="p1">{{ item.name }}</p>
                    <p class="p2">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
                <div class="Ditem" v-show="index == 0">
                  <div class="Dimg">
                    <div
                      class="yinimg"
                      :style="'background-image: url(' + clickitem.img + ')'"
                      alt=""
                    ></div>
                  </div>
                  <div class="wenben">
                    <p class="p1">{{ clickitem.title }}</p>
                    <p class="p2">
                      {{ clickitem.text }}
                    </p>
                    <div class="but" style="margin-top: 0;" @click="butClick(item)">
                      了解更多&nbsp; →
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="layout">
                <div
                  class="layoutList"
                  v-for="(item, index) in industrylist"
                  :key="index"
                >
                  <div
                    class="item"
                    @click="clicklayout(item, index)"
                    :class="{ Dactived: everyIndex == index }"
                  >
                    <div class="layoutImg">
                      <div
                        class="item-img"
                        :style="'background-image: url(' + item.image + ')'"
                        src=""
                        alt=""
                      ></div>
                    </div>
                    <div class="item-wenben">
                      <p class="p1">{{ item.name }}</p>
                      <p class="p2">
                        {{ item.description }}
                      </p>
                      <div
                        class="but"
                        style="margin-top: 0.3rem"
                        @click="butClick(item)"
                      >
                        {{ lookMore }}&nbsp; →
                      </div>
                    </div>
                  </div>
                  <!-- <div class="Ditem" v-show="index == 0">
                    <div class="Dimg">
                      <div
                        class="yinimg"
                        :style="'background-image: url(' + clickitem.img + ')'"
                        alt=""
                      ></div>
                    </div>
                    <div class="wenben">
                      <p class="p1">{{ clickitem.title }}</p>
                      <p class="p2">
                        {{ clickitem.text }}
                      </p>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="four">
      <div v-for="(item, index) in fourlist" :key="index">
        <div class="four-content" v-show="index == fourindex">
          <div class="left">
            <p class="title">{{ technologyTitleCn }}</p>
            <p class="en">{{ technologyTitleEn }}</p>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.49rem;
              "
            >
              <p class="type">{{ item.title }}</p>
              <p class="id">
                0{{ index + 1
                }}<span style="color: #e1e1e1"
                  >&nbsp;/&nbsp;0{{ fourlist.length }}</span
                >
              </p>
            </div>
            <div
              style="
                width: 100%;
                height: 1px;
                background-color: #e1e1e1;
                margin-top: 0.38rem;
                margin-bottom: 0.32rem;
              "
            ></div>
            <p class="text">{{ item.brief }}</p>
            <div class="but" @click="technologyClick(item)">
              了解更多 &nbsp;→
            </div>
          </div>
          <div class="mid">
            <div>
              <img
                :class="'midimg' + index"
                :src="item.image"
                style="width: 100%; height: 33.3%"
                alt=""
              />
              <img
                v-if="fourlist[fourindex + 1]"
                :src="fourlist[fourindex + 1].image"
                style="width: 100%; height: 33.3%"
                alt=""
              />
            </div>
          </div>
          <div class="right">
            <img
              @click="top(index)"
              :src="topimg"
              style="margin: 2.22rem 0 0.24rem"
              alt=""
            />
            <img @click="bottom(index)" :src="downimg" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="four">
      <div>
        <div class="four-content">
          <div
            class="left fixed-image"
            v-for="(item, index) in fourlist"
            :key="index"
            v-show="index == fourindex"
            :style="{ position: position, top: topT }"
          >
            <p class="title">{{ technologyTitleCn }}</p>
            <p class="en" v-if="getLangText === 'CN'">{{ technologyTitleEn }}</p>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.49rem;
              "
            >
              <p class="type">{{ item.title }}</p>
              <p class="id">
                0{{ index + 1
                }}<span style="color: #e1e1e1"
                  >&nbsp;/&nbsp;0{{ fourlist.length }}</span
                >
              </p>
            </div>
            <div
              style="
                width: 100%;
                height: 0.01rem;
                background-color: #e1e1e1;
                margin-top: 0.38rem;
                margin-bottom: 0.32rem;
              "
            ></div>
            <p class="text" v-html="item.brief"></p>
            <div class="but" @click="technologyClick(item)">
              {{ lookMore }} &nbsp;→
            </div>
          </div>
          <div class="scrollable-content" @scroll="handleScroll">
            <div class="mid content-wrapper">
              <div
                class="fourImg"
                v-for="(item, index) in fourlist"
                :key="index"
              >
                <img
                  :id="`bore${index}`"
                  :class="'midimg' + index"
                  :src="item.image"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="five">
      <div class="content">
        <div class="left">
          <p class="p1">{{ honorTitleCn }}</p>
          <p class="p2" v-if="getLangText === 'CN'">{{ honorTitleEn }}</p>
          <div class="fiveLeft">
            <p
              :class="index == fiveindex ? 'fivep4' : 'fivep3'"
              v-for="(item, index) in fivelist"
              :key="index"
            >
              <span v-html="item.title"></span>
            </p>
          </div>
          <div class="anniu">
            <img
              :src="leftimg"
              style="margin-right: 0.3rem"
              alt=""
              @click="prevclick()"
            />
            <img :src="rightimg" @click="nextclick()" alt="" />
          </div>
        </div>
        <div class="right">
          <img
            src="../assets/home/hu.png"
            style="width: 100%; height: 106%; margin-left: 3.33rem"
            alt=""
          />
          <div
            ref="zhengshuRef"
            :class="'zhengshu' + index"
            class="zhengshu"
            v-for="(item, index) in fivelist"
            :key="index"
          >
            <img
              @mousewheel="handleMouseWheel"
              @mouseleave="enableScroll"
              @mouseenter="handleMouseEnter"
              :src="item.image"
              style="width: 4.6rem; height: 4.6rem; object-fit: contain"
              alt=""
            />
            <!-- <img
              v-show="isFlg && index === fiveindex"
              @mousewheel="handleMouseWheel"
              @mouseleave="enableScroll"
              @mouseenter="handleMouseEnter"
              :src="item.image"
              style="width: 4.6rem; height: 4.6rem; object-fit: contain"
              alt=""
            /> -->
            <!-- <img
              v-show="index !== fiveindex"
              @mousewheel="handleMouseWheel"
              @mouseleave="enableScroll"
              @mouseenter="handleMouseEnter"
              :src="item.image"
              style="width: 4.6rem; height: 4.6rem; object-fit: contain"
              alt=""
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="six" v-if="getLangText === 'CN'">
      <div class="content">
        <div class="top">
          <div style="">
            <p class="p1">{{ newsTitleCn }}</p>
            <p class="p2">{{ newsTitleEn }}</p>
          </div>
          <div class="but" @click="newsDetailClick">
            {{ learnInfor }} &nbsp;→
          </div>
        </div>
        <div class="main">
          <div class="left" @click="newsLeftClcik">
            <div style="width: 5.62rem; height: 3.46rem; overflow: hidden">
              <img
                :src="newOne.image"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
            <p style="font-size: 0.16rem; color: #666">
              {{ newOne.create_time }}
            </p>
            <p class="title">{{ newOne.title }}</p>
            <p class="jian">→</p>
          </div>
          <div class="right">
            <div
              class="item"
              v-for="(item, index) in newsList"
              :key="index"
              @click="newsClick(item)"
              v-show="index > 0 && index < 4"
            >
              <p style="font-size: 0.16rem; color: #666">
                {{ item.create_time }}
              </p>
              <p class="title">
                {{ item.title }}
              </p>
              <div style="width: 1.64rem; height: 1.01rem; overflow: hidden">
                <img
                  :src="item.image"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <p class="jian">→</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import tou from "../components/tou.vue";
import foot from "../components/foot.vue";
import swiper from "../components/swiper.vue";
import countTo from "vue-count-to";
import {
  getHomeAbout,
  getHomeLayout,
  getHomeTechnology,
  getHomeHonor,
  getNewsView,
} from "@/api/index";
// Import Swiper styles
export default {
  components: {
    tou,
    swiper,
    countTo,
    foot,
  },
  data() {
    return {
      getLangText: localStorage.getItem("lang") || "CN",
      lookMore: this.$t("look"),
      learnInfor: this.$t("indexLearn"),
      show: true,
      numshow: true,
      // 关于恒联
      home_video: "",
      home_about: "",
      aboutLeft_img: "",
      aboutRight_img: "",
      videoPlay: false,
      numlist: [
        {
          num: 61,
          dan: this.$t("million"),
          name: this.$t("totalAsset"),
          duration: 3000,
        },
        {
          num: 4000,
          dan: "+",
          name: this.$t("numberEmploy"),
          duration: 2900,
        },
        {
          num: 700,
          dan: "+",
          name: this.$t("professionalTec"),
          duration: 3000,
        },
      ],
      // 产业布局
      layoutTitleCn: this.$t("indexLayout"),
      layoutTitleEn: "INDUSTRIAL LAYOUT",
      layoutText: this.$t("layoutText"),
      clickitem: {
        img: require("../assets/home/image2.png"),
        title: "特种纸浆产业",
        text: "公司主要产品有胶版印刷纸、果袋原纸、防粘工业用纸、包装纸等",
      },
      clickindex: 1,
      threeback: "",
      industrylist: [
        // {
        //   id: 1,
        //   img: require("../assets/home/image1.png"),
        //   backimg: require("../assets/home/senlin.png"),
        //   title: "生物纤维产业",
        //   text: "致力于为人们美好生活提供绿色环保型包装材料——纤维素肠衣及纤维素新材料。",
        // },
        // {
        //   id: 2,
        //   img: require("../assets/home/image2.png"),
        //   backimg: require("../assets/home/senlin.png"),
        //   title: "特种纸浆产业",
        //   text: "公司主要产品有胶版印刷纸、果袋原纸、防粘工业用纸、包装纸等",
        // },
        // {
        //   id: 3,
        //   img: require("../assets/home/image3.png"),
        //   backimg: require("../assets/home/threeback1.png"),
        //   title: "卫生材料产业",
        //   text: "致力于打造新一代环保型清洁材料及高品质生活用纸。 ",
        // },
        // {
        //   id: 4,
        //   backimg: require("../assets/home/threeback2.png"),
        //   img: require("../assets/home/image4.png"),
        //   title: "其他产业",
        //   text: "致力于打造新一代环保型清洁材料及高品质生活用纸。",
        // },
      ],
      count: 0,
      everyIndex: 1,
      transform: "",
      left: "",
      topi: "",
      // 科技创新
      technologyTitleCn: this.$t("indexTechnology"),
      technologyTitleEn: "EXCELLENT TECHNOLOGY",
      fourindex: 0,
      fourlist: [
        // {
        //   id: 1,
        //   img: require("../assets/home/four1.png"),
        //   type: "技术中心",
        //   text: "恒联集团坚持创新驱动和差异化发展战略，坚持以创新求发展、以创新促转型，高度重视科技创新工作，重视重大科研成果的培育，先后建成山东省企业技术中心、山东省玻璃纸工程技术研究中心、山东省“一企一技术”研发中心、市级企业技术中心、市级“一企一技术”研发中心、市级重点实验室等23个省、市级科研创新平台，为集团科技创新和跨越式发展奠定了坚实基础。",
        // },
        // {
        //   id: 2,
        //   img: require("../assets/home/four2.png"),
        //   type: "技术中心2",
        //   text: "恒联集团坚持创新驱动和差异化发展战略，坚持以创新求发展、以创新促转型，高度重视科技创新工作，重视重大科研成果的培育，先后建成山东省企业技术中心、山东省玻璃纸工程技术研究中心、山东省“一企一技术”研发中心、市级企业技术中心、市级“一企一技术”研发中心、市级重点实验室等23个省、市级科研创新平台，为集团科技创新和跨越式发展奠定了坚实基础。",
        // },
        // {
        //   id: 3,
        //   img: require("../assets/home/four3.png"),
        //   type: "技术中心3",
        //   text: "恒联集团坚持创新驱动和差异化发展战略，坚持以创新求发展、以创新促转型，高度重视科技创新工作，重视重大科研成果的培育，先后建成山东省企业技术中心、山东省玻璃纸工程技术研究中心、山东省“一企一技术”研发中心、市级企业技术中心、市级“一企一技术”研发中心、市级重点实验室等23个省、市级科研创新平台，为集团科技创新和跨越式发展奠定了坚实基础。",
        // },
      ],
      topimg: require("../assets/home/top.png"),
      downimg: require("../assets/home/down1.png"),
      position: "relative",
      topT: "100px",
      // 集团荣誉
      honorTitleCn: this.$t("indexHonor"),
      honorTitleEn: "GROUP HONOR",
      fivelist: [
        // {
        //   title: "潍坊民营企业100强称号",
        //   img: require("../assets/home/image59.png"),
        // },
        // {
        //   title:
        //     "“高性能木材化学浆绿色制备与高值利用关键技术及产业化”项目，荣获2020年度国家科学技术进步二等奖。",
        //   img: require("../assets/home/image59.png"),
        // },
        // {
        //   title: "证书3",
        //   img: require("../assets/home/image60.png"),
        // },
        // {
        //   title: "证书4",
        //   img: require("../assets/home/image59.png"),
        // },
        // {
        //   title: "证书5",
        //   img: require("../assets/home/image60.png"),
        // },
      ],
      leftimg: require("../assets/home/left1.png"),
      rightimg: require("../assets/home/right1.png"),
      fiveindex: 1,
      isFlg: true,
      // 新闻中心
      newsTitleCn: this.$t("indexNew"),
      newsTitleEn: "NEWS",
      newsList: [],
      newOne: {},
    };
  },
  created() {
    if (this._isMobile()) {
      this.$router.replace("/hlmobile/mobileHome");
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.now = this.cn;
    // 关于恒联
    this.getAbout();
    // 产业布局
    this.getLayout();
    // 科技创新
    this.getTechnology();
    //集团荣誉
    this.getHonor();
    // 新闻中心
    this.getNews();
    // 根据不同路由跳转不同页面
    window.addEventListener("wheel", this.closeSelect);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScrolls);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleScrolls);
  },
  activated() {
    this.periodicRefresh();
    window.addEventListener("wheel", this.closeSelect);
  },
  deactivated() {
    // 页面关闭（路由跳转）时清除定时器
    clearInterval(this.queueTableDataTimer);
    this.queueTableDataTimer = null;
    // 页面跳转清除 滚动事件
    window.removeEventListener("wheel", this.closeSelect);
  },
  watch: {
    // fourindex(newVal, oldVal) {
    //   console.log(`变量从${oldVal}变化到${newVal}`);
    //   // 这里可以添加更多的逻辑来响应变量的变化
    //   if (newVal == 2) {
    //     this.downimg = require("../assets/home/down.png");
    //   } else if (newVal == 0) {
    //     this.topimg = require("../assets/home/top.png");
    //   } else if (this.fourindex < 2 || this.fourindex > 0) {
    //     this.downimg = require("../assets/home/down1.png");
    //     this.topimg = require("../assets/home/top1.png");
    //   }
    // },
    fiveindex(newVal, oldVal) {
      console.log(`变量从${oldVal}变化到${newVal}`);
      // 这里可以添加更多的逻辑来响应变量的变化
      if (newVal == this.fivelist.length - 1) {
        this.rightimg = require("../assets/home/right.png");
      } else if (newVal == 0) {
        this.leftimg = require("../assets/home/left.png");
      } else if (this.fiveindex < 2 || this.fiveindex > 0) {
        this.rightimg = require("../assets/home/right1.png");
        this.leftimg = require("../assets/home/left1.png");
      }
    },
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    handleMouseEnter() {
      document.body.style.overflow = "hidden";
    },
    enableScroll() {
      document.body.style.overflow = "auto";
    },
    handleMouseWheel(event) {
      // 标准的浏览器使用wheelDelta
      if (event.wheelDelta) {
        if (event.wheelDelta > 0) {
          this.prevclick();
        } else {
          this.nextclick();
        }
      }
      // 现代浏览器使用deltaY
      else if (event.deltaY) {
        if (event.deltaY > 0) {
          this.prevclick();
        } else {
          this.nextclick();
        }
      }
    },
    closeSelect() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("滚动监听到了", scrollTop);
      const tou = document.querySelector(".tou");
      // const tou2 = document.querySelector(".tou2");
      // if (scrollTop > 300) {
      //   this.show = false;
      //   // tou.style.animation ="opa1 0.5s linear"
      //   tou2.style.animation = "opa 0.3s ease";
      // } else if (scrollTop < 300) {
      //   this.show = true;
      //   // tou.style.animation ="opa 0.3s ease"
      //   tou2.style.animation = "opa1 0.3s ease";
      // }
    },

    // 关于恒联 打开视频弹窗
    videoClick() {
      this.videoPlay = true;
    },
    // 关于恒联 关闭视频弹窗
    iconFalse() {
      this.videoPlay = false;
    },
    getAbout() {
      getHomeAbout({}).then((data) => {
        console.log(data, "关于恒联");
        this.home_about = data.data.home_about;
        this.home_video = data.data.videos;
        this.aboutLeft_img = data.data.video_image;
        this.aboutRight_img = data.data.images;
        this.numlist[0].num = data.data.asset;
        this.numlist[1].num = data.data.employees;
        this.numlist[2].num = data.data.talents;
        console.log('this.numlist',this.numlist);
      });
    },
    layoutGoLink() {
      this.$router.push({
        path: "/about",
      });
    },
    // 产业布局
    getLayout() {
      getHomeLayout({}).then((data) => {
        // console.log(data, "产业布局");
        this.layoutText = data.data[0].keywords;
        this.industrylist = data.data;
        this.threeback = data.data[1].images;
        this.clickitem.img = data.data[0].image;
        this.clickitem.title = data.data[0].name;
        this.clickitem.text = data.data[0].description;
      });
    },
    butClick(item) {
      this.$router.push({
        path: "/layoutView",
        query: { id: item.id },
      });
      window.sessionStorage.setItem("anchorPoint", item.name);
    },
    clicklayout(item, index) {
      console.log(item, index);
      this.clickindex = index + 1;
      console.log(this.clickindex);
      this.threeback = item.images;
      this.everyIndex = index;
      if (index == 2) {
        this.transform = "rotate(52deg)";
        this.left = "2.56";
        this.topi = "4";
      } else if (index == 3) {
        this.transform = "rotate(104deg)";
        this.left = "1.6";
        this.topi = "4.2";
      } else if (index == 0) {
        this.transform = "rotate(300deg)";
        this.left = "2.46";
        this.topi = "2";
      } else {
        this.transform = "rotate(0deg)";
        this.left = "3.01";
        this.topi = "2.97";
      }
      // this.industrylist[index] = this.industrylist[0];
      // console.log(this.industrylist[index]);
      // this.clickitem = item;
      // this.clickitem.img = item.image;
      // this.clickitem.title = item.name;
      // this.clickitem.text = item.description;
      // this.industrylist[0] = this.clickitem;

      // 移除目标项，并在数组开始处添加
      // const obj = this.industrylist.splice(index, 1);
      // this.industrylist.unshift(item);
      // console.log(this.industrylist);
    },

    // 科技创新
    getTechnology() {
      getHomeTechnology({}).then((data) => {
        // console.log(data, "科技创新");
        this.fourlist = data.data.map((item, index) => {
          item.idsHtml = `bore${index}`;
          return item;
        });
        console.log(this.fourlist);
      });
    },
    technologyClick(item) {
      this.$router.push({
        path: "/technologyView",
        query: { id: item.id },
      });
    },
    handlScroll: function (event) {
      // console.log('处理滚动事件')
      const img0 = document.querySelector(".midimg0");
      const img1 = document.querySelector(".midimg1");
      const img2 = document.querySelector(".midimg3");
      const mid = document.querySelector(".mid");
      console.log(img0);
      img0.style.animation = "anmite3 1s ease";
      img0.style.opacity = `0`;
      img1.style.background = `../assets/2.png`;
    },

    mouseWheel(e) {
      console.log("滚动", e);
      // const img0 = document.querySelector(".midimg0");
      // const img1 = document.querySelector(".midimg1");
      // const img2 = document.querySelector(".midimg2");
      // const mid = document.querySelector(".four-content");
      // // console.log('mid高度=>',mid.clientHeight)
      // if (e.wheelDelta || e.detail) {
      //   if (e.wheelDelta > 0 || e.detail < 0) {
      //     //当鼠标滚轮向上滚动时
      //     if (this.count == 2) {
      //       this.count--;
      //       console.log("向上", this.count);
      //       img1.style.animation = "anmite5 1s ease";
      //       img1.style.height = `5.98rem`;
      //     } else if (this.count == 1) {
      //       this.count--;
      //       console.log("向上", this.count);
      //       img0.style.animation = "anmite5 1s ease";
      //       img0.style.height = `5.98rem`;
      //     }
      //   }
      //   if (e.wheelDelta < 0 || e.detail > 0) {
      //     //当鼠标滚轮向下滚动时
      //     if (this.count == 0) {
      //       this.count++;
      //       console.log("向下", this.count);
      //       img0.style.animation = "anmite3 1s ease";
      //       setTimeout(function () {
      //         img0.style.height = `0`;
      //       }, 500);
      //     } else if (this.count == 1) {
      //       this.count++;
      //       console.log("向下", this.count);
      //       img1.style.animation = "anmite3 1s ease";
      //       setTimeout(function () {
      //         img1.style.height = `0`;
      //       }, 500);
      //     }
      //   }
      // }
    },
    handleScrolls() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const anchors = document.querySelectorAll('img[id^="bore"]');
      console.log(anchors,'anchors');
      let currentAnchor = null;
      for (let i = anchors.length - 1; i >= 0; i--) {
        if (scrollTop >= anchors[i].offsetTop - 160) {
          currentAnchor = anchors[i];
          break;
        }
      }
      console.log(currentAnchor,'currentAnchor');
      if (currentAnchor) {
        let indexValue = this.fourlist.findIndex(
          (item) => item.idsHtml === currentAnchor.id
        );
        console.log(indexValue,'indexValue');
        if (indexValue != -1) {
          this.fourindex = indexValue;
        }
      }
    },
    handleScroll(event) {
      var offsetTop = document.querySelector(".fixed-image").clientHeight;
      var offsetTopT = document.querySelector(".content-wrapper").clientHeight;
      if (offsetTop === offsetTopT) {
        this.position = "sticky";
        this.topT = "130px";
      } else {
        this.position = "sticky";
        this.topT = "130px";
      }
      const scrollableContent = event.target;
      // console.log(scrollableContent.clientHeight, 22222222333333333);
      // 检查内容是否滚动到底部
      if (
        scrollableContent.scrollHeight - scrollableContent.scrollTop ===
        scrollableContent.clientHeight
      ) {
        // 滚动完成后执行的操作
        this.operationAfterScrollComplete();
      }
    },
    operationAfterScrollComplete() {
      // 滚动完成后的逻辑
      console.log("Scrolling has completed.");
    },
    top(index) {
      console.log("向上", index);
      const img0 = document.querySelector(".midimg0");
      const img1 = document.querySelector(".midimg1");
      const img2 = document.querySelector(".midimg2");
      // console.log("img0=>", img0);
      if (index > 0) {
        this.fourindex = index - 1;
        img0.style.animation = "anmite5 0.5s linear forwards";
        img1.style.animation = "anmite5 0.5s linear forwards";
        img2.style.animation = "anmite5 0.5s linear forwards";
      }
    },
    bottom(index) {
      console.log("向下", index);
      const img0 = document.querySelector(".midimg0");
      const img1 = document.querySelector(".midimg1");
      const img2 = document.querySelector(".midimg2");
      // console.log("img0=>", img0);
      if (index < 2) {
        this.fourindex = index + 1;
        img0.style.animation = "anmite6 0.5s linear forwards";
        img1.style.animation = "anmite6 0.5s linear forwards";
        img2.style.animation = "anmite6 0.5s linear forwards";
      }
    },

    //集团荣誉
    getHonor() {
      getHomeHonor({}).then((data) => {
        console.log(data, "集团荣誉");
        this.fivelist = data.data;
      });
    },
    prevclick() {
      let zhengshuRef = this.$refs.zhengshuRef;
      zhengshuRef[0].style = {};
      zhengshuRef[1].style = {};
      zhengshuRef[2].style = {};
      const lastItem = this.fivelist.pop();
      this.fivelist.unshift(lastItem);
      setTimeout(() => {
        zhengshuRef[1].style.animation = "honorTranslate 0.5s linear forwards";
      }, 100);
      setTimeout(() => {
        zhengshuRef[0].style.animation = "honorTranslate 0.5s ease-in-out";
        zhengshuRef[2].style.animation = "honorTranslate 0.5s linear forwards";
      }, 300);
    },
    nextclick() {
      let zhengshuRef = this.$refs.zhengshuRef;
      zhengshuRef[0].style = {};
      zhengshuRef[1].style = {};
      zhengshuRef[2].style = {};
      // console.log("证书下一个");
      // 移除数组第一项并将其追加到数组末尾
      this.fivelist.push(this.fivelist.shift());
      setTimeout(() => {
        zhengshuRef[1].style.animation = "honorTranslate 0.5s ease-in-out";
      }, 100);
      setTimeout(() => {
        zhengshuRef[0].style.animation = "honorTranslate 0.5s ease-in-out";
        zhengshuRef[2].style.animation = "honorTranslate 0.5s ease-in-out";
      }, 200);
    },

    // 新闻中心
    getNews() {
      getNewsView({ type: 1 }).then((data) => {
        // console.log(data, "新闻中心");
        this.newsList = data.data;
        this.newOne = data.data[0];
      });
    },
    newsDetailClick() {
      this.$router.push({
        path: "/newsView",
      });
    },
    newsLeftClcik() {
      this.$router.push({
        path: "/newsDetail",
        query: { id: this.newOne.id },
      });
    },
    newsClick(item) {
      this.$router.push({
        path: "/newsDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.but {
  color: #cd000c;
  border: 1px solid #cd000c;
  width: max-content;
  padding: 0.16rem 0.26rem;
  box-sizing: border-box;
  // line-height: 0.6rem;
  margin-top: 0.71rem;
  cursor: pointer;
  //styleName: 正文;
  //font-family: Microsoft YaHei;
  font-size: 0.16rem;
  font-weight: 400;
  transition: all 0.3s linear;
  text-align: center;
}

.but:hover {
  color: #fff;
  background: #cd000c;
}

.two {
  position: relative;
  width: 100%;
  height: 10rem;

  .backimg {
    width: 10.22rem;
    height: 2.4rem;
    position: absolute;
    top: 40%;
    left: 8%;
    // animation: translate1 2s ease-in;
    z-index: -1;
  }

  .content {
    width: 16rem;
    height: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    box-sizing: border-box;
    display: flex;

    .left {
      width: 7.42rem;
      height: 100%;
      margin-top: 0.32rem;

      .videoImg {
        width: 5.12rem;
        height: 3.07rem;
        margin-left: 2.31rem;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
        video{
          width: 100%;
          height: 100%;
        }

        .videoButton {
          width: 0.8rem;
          height: 0.8rem;
          position: absolute;
          top: 1.13rem;
          left: 2.16rem;
          cursor: pointer;
          display: none;
        }

        .videoButton::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: -0.02rem;
          left: -0.02rem;
          pointer-events: none;
          background-color: transparent;
          border-style: solid;
          border-width: 2px;
          border-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          opacity: 0;
          transition: opacity 0.5s, transform 0.5s;
        }

        .videoButton:hover:after {
          opacity: 1;
          transform: scale(1.04);
        }
      }

      .videoPlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(55, 58, 71, 0.6);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        .videoPut {
          width: 70vw;
          height: auto;
          padding: 0.3rem;
          background: #fff;
          position: relative;

          .videoIcon {
            position: absolute;
            right: 0.1rem;
            top: 0.13rem;
            z-index: 3;
            cursor: pointer;

            i {
              font-size: 0.3rem;
            }
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .num {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1.8rem;
      }

      .num-item {
        margin-right: 0.8rem;
      }

      .num-top {
        // font-family: Roboto;
        font-size: 0.48rem;
        font-weight: 700;
        line-height: 0.62rem;
        text-align: left;
        color: rgba(205, 0, 12, 1);
        display: flex;
        align-items: baseline;

        .dan {
          //font-family: Microsoft YaHei;
          font-size: 0.2rem;
          font-weight: 700;
          line-height: 0.28rem;
          text-align: left;
        }
      }

      p {
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.22rem;
        text-align: left;
        color: #1b1b1b;
        white-space: nowrap;
      }
    }

    .right {
      width: 8.58rem;
      height: 100%;

      .right-top {
        margin-left: 1rem;
      }

      p {
        text-align: left;
        width: 7.2rem;
      }

      .p1 {
        //font-family: Microsoft YaHei;
        font-size: 0.46rem;
        font-weight: 700;
        line-height: 0.64rem;
        color: #1b1b1b;
      }

      .p2 {
        // font-family: Roboto;
        font-size: 0.24rem;
        font-weight: 700;
        line-height: 0.33rem;
        color: #c0c0c0;
        margin-top: 0.04rem;
      }

      .p3 {
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.32rem;
        text-align: left;
        margin-top: 0.26rem;
        color: #666666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        // -webkit-line-clamp: 5;
        // overflow: hidden;
        text-align: justify;
        height: 1.6rem;
      }
    }
  }
}

.three {
  width: 100%;
  height: 100vh;
  background-size: 100% 100vh;
  position: relative;

  .background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: background-image 1s;
    /* 背景图变化动效 */
  }

  @keyframes background-fade {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  .content {
    width: 16rem;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    display: flex;
    align-items: center;

    .left {
      width: 4.32rem;

      // padding-top: 37%;
      p {
        text-align: left;
      }

      .p1 {
        //font-family: Microsoft YaHei;
        font-size: 0.46rem;
        font-weight: 700;
        line-height: 0.69rem;
        white-space: nowrap;
      }

      .p2 {
        // font-family: Roboto;
        font-size: 0.24rem;
        font-weight: 700;
        line-height: 0.33rem;
        margin-top: 0.04rem;
        width: 100%;
      }

      .p3 {
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.28rem;
        text-align: left;
        margin-top: 0.26rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        // -webkit-line-clamp: 4;
        overflow: hidden;
        text-align: justify;
      }

      .p4 {
        // font-family: Roboto;
        font-size: 0.4rem;
        font-weight: 500;
        line-height: 0.7rem;
        text-align: left;
        margin-top: 0.5rem;

        span {
          // font-family: Roboto;
          font-size: 0.2rem;
          font-weight: 500;
          line-height: 0.35rem;
          text-align: left;
          color: rgb(255, 255, 255, 0.6);
        }
      }
    }

    .mid {
      position: relative;
      height: 100%;
      width: 11.68rem;

      .quan1 {
        width: 6.12rem;
        height: 6.12rem;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        position: absolute;
        top: 17.4%;
        left: 0.62rem;
      }

      .quan2 {
        width: 3rem;
        height: 3rem;
        border: 1px solid rgba(255, 255, 255, 0.65);
        border-radius: 50%;
        position: absolute;
        left: 1.56rem;
        top: 1.56rem;
      }

      .xian {
        width: 2.39rem;
        height: 0.1rem;
        position: absolute;
        left: 3.01rem;
        top: 2.97rem;
        z-index: 2;
      }
    }

    .Ditem {
      position: absolute;
      left: 5.19rem;
      top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .yin1 {
        width: 1.7rem;
        height: 1.7rem;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        // left: 5.2rem;
        // top: 2.2rem;
        animation: dotL 2s ease-out infinite;
      }

      .yin2 {
        width: 1.7rem;
        height: 1.7rem;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        // left: 5.2rem;
        // top: 2.2rem;
        animation: dotL 2s ease-out infinite;
      }

      // .yin2 {
      //   width: 1.2rem;
      //   height: 1.2rem;
      //   background: rgba(255, 255, 255, 0.3);
      //   border-radius: 50%;
      //   position: absolute;
      //   left: 14%;
      //   top: 14%;
      //   animation: dotL 2s ease-out infinite;
      // }

      .yinimg {
        position: relative;
        z-index: 1;
        width: 1.71rem;
        height: 1.71rem;
        border-radius: 50%;
        border: 0.05rem solid rgba(205, 0, 12, 1);
        box-sizing: border-box;
        transition: all 0.5s linear;
        transition: background-image 1s;
        /* 背景图变化动效 */
        background-size: 1.71rem 1.71rem;
        background-origin: border-box;
      }

      .wenben {
        margin-left: 0.43rem;
        width: 3.2rem;
        height: 2.03rem;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .p1 {
          //font-family: Microsoft YaHei;
          font-size: 0.4rem;
          font-weight: 700;
          line-height: 0.52rem;
          text-align: left;
        }

        .p2 {
          //font-family: Microsoft YaHei;
          font-size: 0.16rem;
          font-weight: 400;
          line-height: 0.32rem;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .but {
          border: 1px solid rgba(255, 255, 255, 0.4);
          background: rgba(255, 255, 255, 0.1);
          //font-family: Microsoft YaHei;
          font-size: 0.16rem;
          font-weight: 400;
          line-height: 0.52rem;
          text-align: center;
          width: 1.45rem;
          height: 0.52rem;
          color: #fff;
        }
      }
    }

    .Ditem:hover .but {
      background: rgba(205, 0, 12, 1);
      border: 1px solid rgba(205, 0, 12, 1);
    }

    // .item:hover .item-img {
    //   transform: scale(1.2);
    // }
    .Dactived {
      .item-img {
        position: relative;
        width: 1.7rem !important;
        height: 1.7rem !important;
        border-radius: 50%;
        border: 0.05rem solid rgba(205, 0, 12, 1);
        /* 背景图变化动效 */
        background-size: 1.7rem 1.7rem !important;
        background-origin: border-box;
        z-index: 1;
      }

      .item-wenben {
        .p1 {
          //font-family: Microsoft YaHei;
          font-size: 0.4rem;
          font-weight: 700;
          line-height: 0.52rem;
          text-align: left;
        }

        .but {
          display: block;
        }
      }

      .xian {
        display: block !important;
      }
    }

    .Dactived::before {
      animation: dotL 2s ease-out infinite 1s;
      content: "";
      display: block;
      left: 17%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      // z-index: -1;
      width: 1.7rem;
      height: 1.7rem;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 100%;
    }

    .Dactived::after {
      animation: dotL 2s ease-out infinite;
      content: "";
      display: block;
      left: 17%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      // z-index: -1;
      width: 1.7rem;
      height: 1.7rem;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 100%;
    }

    .Dactived {
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.5s ease-out;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .item-img {
        width: 1rem;
        height: 1rem;
        background-size: 1rem 1rem;
        position: relative;
        border-radius: 50%;
        transition: all 0.5s linear;
        background-origin: border-box;
      }

      .p1 {
        //font-family: Microsoft YaHei;
        font-size: 0.24rem;
        font-weight: 700;
        line-height: 0.42rem;
        text-align: left;
        white-space: nowrap;
      }

      .p2 {
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.32rem;
        text-align: left;
      }

      .item-wenben {
        position: relative;
        justify-content: center;
        width: 3.2rem;
        height: 1.1rem;

        .p2 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .item0 {
      position: absolute;
      top: 2rem;
      left: 5.19rem;

      .yinimg {
        width: 1.71rem;
        height: 1.71rem;
        border-radius: 50%;
        border: 0.05rem solid rgba(205, 0, 12, 1);
        box-sizing: border-box;
      }

      .item-wenben {
        margin-left: 0.43rem;
        width: 3.2rem;
        height: 2.03rem;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .p1 {
          //font-family: Microsoft YaHei;
          font-size: 0.4rem;
          font-weight: 700;
          line-height: 0.52rem;
          text-align: left;
        }

        .p2 {
          //font-family: Microsoft YaHei;
          font-size: 0.16rem;
          font-weight: 400;
          line-height: 0.32rem;
          text-align: left;
        }

        .but {
          border: 1px solid rgba(255, 255, 255, 0.4);
          background: rgba(255, 255, 255, 0.1);
          //font-family: Microsoft YaHei;
          font-size: 0.16rem;
          font-weight: 400;
          line-height: 0.52rem;
          text-align: center;
          width: 1.45rem;
          height: 0.52rem;
        }
      }
    }

    .layoutImg {
      margin-right: 0.25rem;
    }

    .layout {
      .but {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.1);
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        // line-height: 0.52rem;
        text-align: center;
        // width: 1.45rem;
        // height: 0.52rem;
        color: #fff;
        display: none;
      }

      .layoutList:nth-child(2) {
        .item {
          position: absolute;
          left: 5.6rem;
          top: 2.4rem;

          .item-wenben {
            margin-left: 0.16rem;
          }
        }

        .Dactived {
          position: absolute;
          left: 5.1rem;
          top: 2.1rem;

          .item-wenben {
            top: -0.28rem;
          }
        }
      }

      .layoutList:nth-child(3) {
        .item {
          position: absolute;
          top: 5.17rem;
          left: 3.99rem;

          .item-wenben {
            margin-left: 0.16rem;
          }
        }

        .Dactived {
          top: 4.7rem;
        }
      }

      .layoutList:nth-child(4) {
        .item {
          position: absolute;
          top: 5.46rem;
          left: 1.35rem;

          .item-wenben {
            position: absolute;
            top: 0;
            left: -3.6rem;
          }
        }

        .Dactived {
          top: 5.1rem;
          left: 1.35rem;
        }

        .Dactived::before {
          left: 45%;
        }

        .Dactived::after {
          left: 45%;
        }
      }

      .layoutList:nth-child(1) {
        .item {
          position: absolute;
          top: -0.16rem;
          left: 3.73rem;

          .item-wenben {
            margin-left: 0.16rem;
          }
        }

        .Dactived {
          position: absolute;
          top: -0.5rem;
          left: 3.73rem;
        }
      }
    }
  }
}

.four {
  margin: 0.71rem 0;
  width: 100%;

  .four-content {
    width: 16rem;
    margin: auto;
    // max-height: 17.21rem;
    // height: 7.3rem;
    display: flex;
    justify-content: end;
    // overflow: hidden;

    .left {
      width: 32%;
      height: 6.2rem;
      margin-right: 1.24rem;

      .title {
        //font-family: Microsoft YaHei;
        font-size: 0.46rem;
        font-weight: 700;
        line-height: 0.64rem;
        text-align: left;
        color: rgba(27, 27, 27, 1);
      }

      .en {
        // font-family: Roboto;
        font-size: 0.24rem;
        font-weight: 700;
        line-height: 0.33rem;
        text-align: left;
        color: rgba(192, 192, 192, 1);
      }

      .type {
        //font-family: Microsoft YaHei;
        font-size: 0.3rem;
        font-weight: 700;
        line-height: 0.4rem;
        text-align: left;
        color: rgba(207, 0, 14, 1);
      }

      .id {
        // font-family: Roboto;
        font-size: 0.3rem;
        font-weight: 500;
        line-height: 0.45rem;
        text-align: left;
        color: rgba(207, 0, 14, 1);
      }

      .text {
        //font-family: Microsoft YaHei;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.32rem;
        height: 2.24rem;
        color: #666666;
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
      }
    }

    .scrollable-content {
      flex-grow: 1;
      overflow-y: auto;
      // padding-left: 20px;
    }

    .scrollable-content::-webkit-scrollbar {
      display: none;
    }

    .mid {
      width: 9.59rem;
      // height: 100%;
      // height: 7rem;
      // overflow: auto;
      // margin-right: 0.4rem;

      img {
        cursor: pointer;
        transition: all 0.8s;
        /*图片放大过程的时间*/
        box-sizing: border-box;
      }

      img:hover {
        // transform: scale(0.8); /*鼠标经过图片放大的倍数*/
      }
    }

    .right {
      width: 0.7rem;

      img {
        width: 0.5rem;
        height: 0.5rem;
        cursor: pointer;
        transition: all 0.8s;
        /*图片放大过程的时间*/
      }

      img:hover {
        transform: scale(1.2);
        /*鼠标经过图片放大的倍数*/
      }
    }
  }
}

.five {
  width: 100%;
  height: 10rem;
  background: #f8f8f8;
  overflow: hidden;

  .content {
    width: 16rem;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .left {
    width: 50%;

    .p1 {
      //font-family: Microsoft YaHei;
      font-size: 0.46rem;
      font-weight: 700;
      line-height: 0.64rem;
      text-align: left;
      color: #1b1b1b;
    }

    .p2 {
      // font-family: Roboto;
      font-size: 0.24rem;
      font-weight: 700;
      line-height: 33.6px;
      text-align: left;
      color: #c0c0c0;
    }

    .fivep3,
    .fivep4 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      width: 7.5rem;
    }

    .fivep3 {
      //font-family: Microsoft YaHei;
      font-size: 0.24rem;
      font-weight: 400;
      line-height: 0.33rem;
      text-align: left;
      color: #666666;
      margin-bottom: 0.6rem;
    }

    .fivep4 {
      //font-family: Microsoft YaHei;
      font-size: 0.3rem;
      font-weight: 700;
      line-height: 0.5rem;
      text-align: left;
      color: #cd000c;
      margin-bottom: 0.6rem;
    }

    .anniu {
      display: flex;
      align-items: center;

      img {
        width: 0.5rem;
        height: 0.5rem;
        cursor: pointer;
        transition: all 0.8s;
        /*图片放大过程的时间*/
      }

      img:hover {
        transform: scale(1.2);
        /*鼠标经过图片放大的倍数*/
      }
    }
    .fiveLeft {
      height: 3rem;
      overflow: hidden;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0,
        #000 30%,
        #000 99%,
        rgba(0, 0, 0, 0) 100%
      );
      
      margin-top: 1.1rem;
    }
  }

  .right {
    width: 50%;
    height: 100%;
    border-left: 1px solid #cd000c;
    position: relative;
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0px,
      rgb(0, 0, 0) 10%,
      rgb(0, 0, 0) 95%,
      rgba(0, 0, 0, 0) 100%
    );
    .zhengshu {
      position: absolute;
    }

    .zhengshu0 {
      top: -3rem;
      left: 3.4rem;
    }

    .zhengshu1 {
      top: 2.45rem;
      left: 1.6rem;
    }

    .zhengshu2 {
      top: 8.11rem;
      left: 3.42rem;
    }
  }
}

.six {
  // height: 8.39rem;
  width: 100%;

  .content {
    width: 16rem;
    margin: 1rem auto;
    height: 6.19rem;
  }

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .p1 {
      //font-family: Microsoft YaHei;
      font-size: 0.46rem;
      font-weight: 700;
      line-height: 0.64rem;
      text-align: left;
      color: #1b1b1b;
    }

    .p2 {
      // font-family: Roboto;
      font-size: 0.24rem;
      font-weight: 700;
      text-align: left;
      color: #c0c0c0;
    }
  }

  .but {
    margin: 0;
  }

  .main {
    height: 4.8rem;
    width: 100%;
    margin-top: 0.28rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    //styleName: H5;
    //font-family: Microsoft YaHei;
    font-size: 0.18rem;
    font-weight: 400;
    line-height: 0.28rem;
    text-align: left;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5.5rem;
    transition: all 0.5s linear;
  }

  .jian {
    color: #333;
  }

  .left:hover .title {
    color: #cd000c;
  }

  .left:hover .jian {
    color: #cd000c;
    transform: translateX(0.1rem);
  }

  .left:hover img {
    scale: (1.2);
  }

  .left {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 5.62rem;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;

    img,
    .jian {
      transition: all 0.5s linear;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 9.38rem;
    height: 100%;

    .item:hover .title {
      color: #cd000c;
    }

    .item:hover .jian {
      color: #cd000c;
      transform: translateX(0.1rem);
    }

    .item:hover img {
      scale: (1.2);
    }

    .item {
      height: 33.3%;
      width: 100%;
      border-top: 0.01rem solid #e3e3e3;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .title {
        width: 5.8rem;
      }

      img,
      .jian {
        transition: all 0.5s linear;
      }
    }

    .item:nth-last-child(2),
    .item:last-child {
      border-bottom: 0.01rem solid #e3e3e3;
    }
  }
}

::v-deep .tab {
  a {
    color: #fff !important;

    &.router-link-exact-active {
      color: rgba(205, 0, 12, 1) !important;

      span {
        border-bottom: 2px solid rgb(205, 0, 12);
        height: 100%;
        line-height: 0.8rem;
      }
    }
  }

  .logo {
    background-image: url(../assets/2.png) !important;
  }

  .right {
    color: rgba(255, 255, 255, 1) !important;

    .right-img1 {
      background-image: url(../assets/home/search.png);
      background-size: 0.19rem 0.19rem;
    }

    .right-img2 {
      // background-image: url(../assets/home/EN.png);
      background-size: 0.19rem 0.13rem;
    }
  }
}
::v-deep .nav {
  .right {
    color: rgb(51, 51, 51) !important;
  }
}

// ::v-deep .tab:hover {
//   background: #fff !important;

//   a {
//     color: rgba(51, 51, 51, 1) !important;

//     &.router-link-exact-active {
//       color: rgba(205, 0, 12, 1) !important;
//       span {
//         border-bottom: 0.02rem solid rgb(205, 0, 12);
//         height: 100%;
//         line-height: 0.8rem;
//       }
//     }
//   }

//   .logo {
//     background-image: url(../assets/1.png) !important;
//   }
//   .right {
//     color: rgba(227, 227, 227, 1) !important;
//     .right-img1 {
//       background-image: url(../assets/home/search1.png);
//       background-size: 0.19rem 0.19rem;
//     }
//     .right-img2 {
//       background-image: url(../assets/home/EN1.png);
//       background-size: 0.19rem 0.13rem;
//     }
//   }
// }
</style>